@import './fonts/MyFonts Order M12230308/StyleSheetUrbane.css';
@import './fonts/Termina-Full-Family/StyleSheetTermina.css';

html,
body {
    padding: 0;
    margin: 0;
    font-family: 'Urbane', sans-serif;
    overflow-x: hidden;
    min-height: 100vh;
    width: 100%;
    padding-right: 0 !important;
}

.grecaptcha-badge {
    z-index: 9999;
}

#scrollbar::-webkit-scrollbar {
    width: 11px;
}

/* Track */
#scrollbar::-webkit-scrollbar-track {
    background: none;
    border-radius: 10px;
}

/* Handle */
#scrollbar::-webkit-scrollbar-thumb {
    background: #175458;
    border-radius: 10px;
}

/* Handle on hover */
#scrollbar::-webkit-scrollbar-thumb:hover {
    background: #2e8d94;
}

textarea {
    resize: none;
}

.ReactModal__Overlay {
    z-index: 9999;
    background-color: rgba(0, 0, 0, 0.6) !important;
}

.MuiAlert-icon {
    display: none !important;
}

.MuiAlert-message {
    font-family: 'Urbane', sans-serif !important;
}


a {
    color: inherit !important;
    text-decoration: none !important;
}

a:hover {
    color: inherit !important;
}

* {
    box-sizing: border-box;
}

:root {
  --timeout: 350ms;
}

.display-enter {
    opacity: 0;
    transition: opacity 0.2s ease;
}

.display-enter-active {
    opacity: 1;
    transition: opacity 0.2s ease;
}

.display-exit {
    opacity: 1;
    transition: opacity 0.2s ease;
}

.display-exit-active {
    opacity: 0;
    transition: opacity 0.2s ease;
}

.fade-enter {
    opacity: 0.01;
  }
  .fade-enter.fade-enter-active {
    opacity: 1;
    transition: opacity 1s;
    transition-delay: 1s;
  }
  
  .fade-exit {
    opacity: 1;
  }
  .fade-exit.fade-exit-active {
    opacity: 0.01;
    transition: opacity 1s;
  }
