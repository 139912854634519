@font-face {
    font-family: 'Termina';
    src: url('Termina-Black.otf') format('opentype'), 
         url('Termina-Black.woff') format('woff'), 
         url('Termina-Black.woff2') format('woff2');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'Termina';
    src: url('Termina-Bold.otf') format('opentype'), 
         url('Termina-Bold.woff') format('woff'), 
         url('Termina-Bold.woff2') format('woff2');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'Termina';
    src: url('Termina-Demi.otf') format('opentype'), 
         url('Termina-Demi.woff') format('woff'), 
         url('Termina-Demi.woff2') format('woff2');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Termina';
    src: url('Termina-Medium.otf') format('opentype'), 
         url('Termina-Medium.woff') format('woff'), 
         url('Termina-Medium.woff2') format('woff2');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Termina';
    src: url('Termina-Regular.otf') format('opentype'), 
         url('Termina-Regular.woff') format('woff'), 
         url('Termina-Regular.woff2') format('woff2');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Termina';
    src: url('Termina-Light.otf') format('opentype'), 
         url('Termina-Light.woff') format('woff'), 
         url('Termina-Light.woff2') format('woff2');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Termina';
    src: url('Termina-ExtraLight.otf') format('opentype'), 
         url('Termina-ExtraLight.woff') format('woff'), 
         url('Termina-ExtraLight.woff2') format('woff2');
    font-weight: 200;
    font-style: normal;
}

@font-face {
    font-family: 'Termina';
    src: url('Termina-Thin.otf') format('opentype'), 
         url('Termina-Thin.woff') format('woff'), 
         url('Termina-Thin.woff2') format('woff2');
    font-weight: 100;
    font-style: normal;
}

