@font-face {
    font-family: 'Urbane';
    src: url('Device - Urbane Demi Bold.otf') format('opentype');
    font-weight: 600;
    font-style: normal;
    unicode-range: U+0000-005E, U+0060-FFFD;
}

@font-face {
    font-family: 'Urbane';
    src: url('Device - Urbane Light.otf') format('opentype');
    font-weight: 300;
    font-style: normal;
    unicode-range: U+0000-005E, U+0060-FFFD;
}


